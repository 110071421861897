<template>
    <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}"
            :initial-data="initialData" :save-params="{team: $route.params.id}">

        <div class="row">
            <div class="col-12">
                <validated-input label="Name*" v-model="model.name"
                                 :rules="{required: true, max:20}" :disabled="loading"/>
            </div>
            <div class="col-12">
                <file-input class="c-file-input" ref="file" @input="clearFile" label="Image*" name="Image"
                            v-model="model.team_member_image"
                            :disabled="loading">
                    <template #append>
                        <div class="btn-group">
                            <btn v-if="initialData.team_member_image"
                                 @click="viewFile(initialData.team_member_image)"
                                 type="button" class="fa fa-eye" size="xs"/>
                        </div>
                    </template>
                </file-input>
            </div>
            <div class="col-12">
                <validated-input label="Designation*" v-model="model.designation"
                                 :rules="{required: true, max:35}" :disabled="loading"/>
            </div>
<!--            <div class="col-12">-->
<!--                <validated-textarea label="Brief Description" v-model="model.brief_description"-->
<!--                                    :rules="{required: true, max:10}" :disabled="loading"/>-->
<!--            </div>-->
            <div class="col-12">
                <validated-text-area label="Full Description*" v-model="model.full_description"
                                    :rules="{required: true, max:500}" :disabled="loading"/>
            </div>
            <div class="col-12">
                <validated-input type="number" label="Order*" v-model="model.order"
                                 :rules="{required: true, min_value:1}" :disabled="loading"/>
            </div>
            <div class="col-12">
                <validated-checkbox label="Enabled" v-model="model.is_enabled"
                                :rules="{required: false}" :disabled="loading"/>
            </div>
            <div class="col-12 text-right">
                <btn class="trn" size="sm" :loading="loading" color="primary" text="Update"
                     loading-text="Updating..." :disabled="loading"/>
            </div>
        </div>
    </b-form>
</template>

<script>
import urls from '@/data/cms-urls';

export default {
    name : 'EditTeam',

    props : { initialData : { type : Object } },

    data () {
        return {
            addUrl : urls.cms.team.addEdit
        };
    },

    methods : {
        clearFile (file) {
            this.initialData.team_member_image = '';
        },
        viewFile (file) {
            window.open(file);
        },
        formSuccess () {
            this.$notify('Successfully Edited Team..!', 'Success',
                {
                    type : 'success'
                }
            );
            this.$emit('success');
        }
    }
};
</script>

<style scoped>

</style>
