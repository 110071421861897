<template>
    <div class="card bs-5 mb-5">

        <div class="fl-te-c pb-3">
            <h4>Our Team</h4>
            <div>
                <btn color="success" icon="fa fa-plus" size="md" @click="$refs.teamAddModal.show()" text="Add"/>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-3">
                <validated-input label="Search" v-model="ext_search"/>
            </div>
        </div>

        <custom-vue-table :fields="fields" :url="listUrl" :per-page="10" :show-search-box="false"
                          class="c-cms-table d-sm-none-lg-block" ref="table"
                          :extra-params="{ ext_search: ext_search }">

            <template #team_member_image="{rowData}">
                <div class="container-image fl-x-cc pos-r" @click="viewFile(rowData.team_member_image)">
                    <img :src="rowData.team_member_image" alt="Avatar" class="c-image cursor-pointer">
                    <div class="c-overlay">
                        <div class="c-icon ml-3">
                            <i class="fa fa-eye cursor-pointer"></i>
                        </div>
                    </div>
                </div>
            </template>

            <template #is_enabled="{rowData}">
                <p v-if="rowData.is_enabled">Yes</p>
                <p v-else>No</p>
            </template>

            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <edit-btn icon="fa fa-eye" text="View" @click="viewDetails(rowData.id)"/>

                    <delete-btn design="basic" @click="setDelete(rowData)"/>
                </div>
            </template>

        </custom-vue-table>
        <team-mobile-view @viewDetails="viewDetails" @setDelete="setDelete" ref="ourTeamMobileView"
                          class="d-lg-none" :ext_search="ext_search"></team-mobile-view>

        <modal title="Add Team" ref="teamAddModal" width="60r" :no-close-on-backdrop="true" header-color="primary">
            <AddTeam @success="formSuccess"></AddTeam>
        </modal>

        <modal title="Edit Team" ref="teamEditModal" :no-close-on-backdrop="true" width="60r" header-color="primary">
            <EditTeam :initial-data="editingItem" @success="formSuccess"></EditTeam>
        </modal>

        <delete-modal ref="teamDeleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
            <p>You are about to delete the Team <b v-html="deletingItem && deletingItem.name"></b>. Are you sure ?
            </p>

            <template #loading>
                <loading-animation/>
                Please wait while we delete <b v-html="deletingItem && deletingItem.name"></b>.
            </template>
        </delete-modal>

    </div>
</template>

<script>
import urls from '@/data/cms-urls';
import AddTeam from './AddTeam';
import EditTeam from './EditTeam';
import TeamMobileView from '@/views/cms/our-team/team/TeamMobileView';

export default {
    name : 'Team',

    components : { TeamMobileView, AddTeam, EditTeam },

    data () {
        return {
            listUrl   : urls.cms.team.list,
            deleteUrl : urls.cms.team.delete,

            editingItem  : null,
            deletingItem : null,

            fields : [
                {
                    name      : 'order',
                    sortField : 'order',
                    title     : 'Order'
                },
                {
                    name      : 'name',
                    sortField : 'name',
                    title     : 'Name'
                },
                {
                    name  : '__slot:team_member_image',
                    title : 'Image'
                },
                {
                    name      : 'designation',
                    sortField : '',
                    title     : 'Designation'
                },
                {
                    name  : '__slot:is_enabled',
                    title : 'Enabled'
                },
                {
                    name       : '__slot:actions',
                    title      : 'Actions',
                    titleClass : 'center bg-gray aligned text-right',
                    dataClass  : 'center aligned text-right'
                }
            ],
            ext_search : ''
        };
    },
    methods : {
        viewFile (file) {
            window.open(file);
        },
        viewDetails (id) {
            this.$router.push('/cms/team/' + id + '/details/');
        },
        formSuccess () {
            const refs = this.$refs;
            refs.teamAddModal.close();
            refs.teamEditModal.close();
            refs.table.refreshTable();
            refs.ourTeamMobileView.loadData();

            this.editingItem = null;
        },

        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.teamEditModal.show();
        },

        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.teamDeleteModal.show();
        },

        deleteComplete (response) {
            if (response.error === true) {
                if (response.message) {
                    this.$notify(response.message, 'Failed', { type : 'danger' });
                } else {
                    this.$notify('Failed to delete, Please try again later', 'Failed',
                        { type : 'danger' }
                    );
                }
            } else {
                this.$notify('Successfully Deleted!', 'Success', { type : 'success' }
                );
            }
            this.deletingItem = null;
            this.$refs.teamDeleteModal.close();
            this.$refs.table.refreshTable();
            this.$refs.ourTeamMobileView.loadData();
        }

    }
};
</script>

<style scoped>

</style>
